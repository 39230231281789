#footer{
    background: #4C749E;
    position: relative;
    padding: 5rem 0;

    @include d(md2){
        padding: 4rem 0;
    }

    .wrapper-footer{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 8.5rem;

        @include d(lg2){
            flex-direction: column;
            justify-content: center;
        }
        @include d(md2){
            gap: 3rem;
        }
    }

    .logo{
        img{
            @include d(md2){
                max-width: 8.9244rem;
            }
        }
    }

    .nav-footer{
        .wrapper-nav-footer{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            gap: 1.25rem;

            @include d(md){
                gap: 7.5rem 1.25rem;
                grid-template-columns: repeat(2, 1fr);
            }

            @include d(md2){
                grid-template-columns: 1fr;
                gap: 3rem; 
            }

            &>.content-1, &>.content-2, &>.content-3{
                @include d(lg2){    
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            } 
        }

        .title-item-nav-footer{
            display: flex;
            justify-content: flex-start;
            @include d(lg2){
                justify-content: center;
            }
            h4{
                
                font-family: $font-3;
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 100%;

                /* identical to box height, or 20px */
                letter-spacing: 0.2em;

                color: #F1ECE0;
                text-transform: uppercase;
                text-align: center;
            }
        }

        .main-item-nav-footer{
            margin-top: 3rem;
            @include d(md2){
                margin-top: 1.5rem;
            }
            .list-item-nav-footer{
                display: flex;
                flex-direction: column;
                gap: 1.875rem;

                @include d(lg2){
                    align-items: center;
                }

                @include d(md2){
                    gap: 1rem;
                }
                li{
                    a{
                        
                        font-family: $font-3;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.125rem;
                        line-height: 100%;

                        /* identical to box height, or 18px */

                        color: #F1ECE0;
                        transition: color .3s ease-in;
                        &:hover{
                            color: #B89E7B;
                        }
                    }
                }
            }

            @include list_contact_network(#F1ECE0, #F1ECE0, #1E2122, #F1ECE0, #B89E7B);
            .list-contact-network{
                display: flex;
                flex-direction: column;
                gap: 1.875rem;

                @include d(md2){
                    gap: 1rem;
                }
                li{
                    a{   
                        font-family: $font-3;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.125rem;
                        line-height: 160%;

                        /* or 29px */

                        //color: #F1ECE0;
                    }
                }
            }
        }
    }
}