//fecha o menu com clip path
@keyframes volta_menu {
    to {
        clip-path: circle(0% at 80% 0%);

    }
}

@keyframes arrow_stages{
    0%,
    100%{
        transform: rotate(0);
    }
    10%,
    30%,
    90%{
        transform: rotate(20deg);
    }
    5%,
    15%,
    45%{
        transform: rotate(-20deg);
    }

}

@keyframes navigation{
    0%{
        transform: translateY(-6.25rem);
    }
    100%{
        transform: translateY(0);
    }
}


//pulse de notificacao
@keyframes pulse {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.1)
    }
}


//vai para
@keyframes opacidade_1 {
    to {
        opacity: 1;
    }
}


//chacoalhada
@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}