.card-stage{
    min-width: 14.9375rem;
    width: max-content;
    position: relative;


    @include d(lg){
        min-width: auto;
        width: 100%;
    }
    .header-card-stage{
        padding: .75rem;
        width: 100%;
        height: 5.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #F1EBE0;
        
        .arrow-next-card-stage{
            position: absolute;
            top: -2.125rem;
            right: -4.625rem;

            animation-name: arrow_stages;
            animation-timing-function: linear;
            animation-duration: 4s;
            animation-delay: 1s;
            animation-iteration-count: infinite;
            @include d(lg){
                right: -1.5rem;
                top: .3rem;
            }
        }
        h4{
           // max-width: 22ch;
            font-family: $font-3;
            font-style: normal;
            font-weight: 600;
            font-size: clamp(1.2rem, 3vw, 1.5rem);
            line-height: 100%;

            /* identical to box height, or 24px */
            letter-spacing: 0.2em;

            text-align: center;

            color: #F1EBE0;
            text-transform: uppercase;
        }
    }

    .main-card-stage{
        margin-top: 1.5rem;
        width: 100%;
        .text-area-card-stage{
            display: flex;
            justify-content: center;
            p{
                max-width: 22ch;
                font-family: $font-1;
                font-style: normal;
                font-weight: 400;
                font-size: clamp(1rem, 3vw, 1.125rem);
                line-height: 160%;

                /* or 29px */
                text-align: center;

                color: #F1EBE0;
            }
        }
    }
}