#mission-vision-values{
    position: relative;

    .box-mission-vision-values{
        .card-mission-vision-values{

            &:nth-child(even){
                .content-1{
                    order: 2;
                }
                .content-2{
                    order: 1;
                }
            }
            .wrapper-mission-vision-values{
                background: #F1EBDF;
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                height: auto;

                @include d(md2){
                    grid-template-columns: 1fr;
                }

                .content-1, .content-2{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .content-1{
                    
                    
                    padding: 3.125rem 10.1563rem;

                    @include header_section(#B09D7C, #1F2122);

                    @include d(lg2){
                        padding: 4rem 5rem;
                    }

                    @include d(md){
                        padding: 4rem 2.5rem;
                    }

                    @include d(md2){
                        padding: 4rem 1.5rem;
                        order: 2 !important;
                    }
                }

                .content-2{
                    height: 42.4375rem;
                    
                    @include d(md2){
                        height: 26.25rem;
                        order: 1 !important;
                    }
                    @include d(sm){
                        height: 18.25rem;
                    }
                    figure{
                        width: 100%;
                        height: 100%;
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        
                    }
                }
            }
        }
    }
}