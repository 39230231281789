body {
  --neutral-100: #090a1a;
  --neutral-70: #464651;
  --neutral-60: #666670;
  --neutral-op-50: #090a1a80;
  --neutral-op-40: #090a1a66;
  --neutral-op-10: #090a1a1a;
  --neutral-op-05: #090a1a0d;
  --neutral-30: #e5e6eb;
  --neutral-20: #f0f1f5;
  --neutral-10: #f5f6fa;
  --color-01: #1e2124;
  --color-02: #626263;
  --color-03: #5e492f;
  --color-04: #b09d7c;
  --color-05: #efeadf;
  --primary-pure: #b09d7c;
  --hue-primary: 230;
  --white-10-op: #ffffff1a;
  --white: #fff;
  --neutral-10: #f5f6fa;
  --primary-light: #8186f7;
  --primary-light-10-op: #8288f71a;
  --white-70-op: #ffffffb3;
  --white: #f8f9fa;
  --black: #f1f3f5;
  --alert-error: #e92c2c;
  --alert-error-10: #fdeaea;
  --alert-warning: #d2af00;
  --alert-warning-10: #fbf7e6;
  --alert-sucess: #008024;
  --alert-sucess-10: #e6f9f0;
}

body, p, h1, h2, h3, h4, h5, h6, ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ol {
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

:after, :before {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

button {
  background: initial;
  border: initial;
  -webkit-appearance: none;
  font-family: Libre Caslon Text;
}

button:active {
  border-style: initial;
}

button:focus {
  outline: initial;
}

button[type="button"], button[type="submit"], button[type="reset"] {
  -webkit-appearance: none;
}

button[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

img {
  object-fit: contain;
  max-width: 100%;
  display: block;
}

fieldset {
  -webkit-margin-start: 0px;
  -moz-margin-start: 0px;
  -webkit-margin-end: 0px;
  -moz-margin-end: 0px;
  border: 2px #0000;
  border-image: initial;
  min-inline-size: min-content;
  margin-inline: 0;
}

figure {
  margin-block: 0;
  margin-inline: 0;
  display: block;
}

:focus {
  outline: 0 #0000 !important;
}

input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

input, [type="button"], select {
  -webkit-appearance: none;
}

:root {
  --xl-device: 1920px;
  --lg-device: 1440px;
  --lg2-device: 1200px;
  --md-device: 1024px;
  --md2-device: 768px;
  --sm-device: 600px;
}

@keyframes volta_menu {
  to {
    -webkit-clip-path: circle(0% at 80% 0);
    clip-path: circle(0% at 80% 0);
  }
}

@keyframes arrow_stages {
  0%, 100% {
    transform: rotate(0);
  }

  10%, 30%, 90% {
    transform: rotate(20deg);
  }

  5%, 15%, 45% {
    transform: rotate(-20deg);
  }
}

@keyframes navigation {
  0% {
    transform: translateY(-6.25rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes opacidade_1 {
  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

body {
  opacity: 0;
  font-family: Libre Caslon Text;
  font-size: 1rem;
  overflow-x: hidden;
}

body.dcl {
  opacity: 1;
}

body.dcl .animation-header {
  pointer-events: none;
  visibility: hidden;
  transform: translateX(100vw);
}

body.dcl #nav-principal:after {
  transform: translateX(100vw);
}

.animation-header {
  z-index: 1000;
  visibility: visible;
  background: #5e492f;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .7s ease-in .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0);
}

.animation-header svg path {
  fill: #fff;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: calc(1216px + 3rem);
  }
}

html {
  --font-base: 100%;
  font-size: var(--font-base);
  overflow-x: hidden;
}

@media (max-width: 1440px) {
  html {
    font-size: calc(var(--font-base)  - 9.25%);
  }
}

@media (max-width: 980px) {
  html {
    font-size: calc(var(--font-base)  - 12.5%);
  }
}

#nav-principal {
  z-index: 999;
  background: #efebe0;
  width: 100%;
  height: 6rem;
  position: relative;
}

#nav-principal:after {
  content: "";
  background: #5e492f;
  width: 100%;
  height: .125rem;
  transition: transform 1s linear 1.6s;
  display: block;
  transform: translateX(0);
}

@media screen and (max-width: 768px) {
  #nav-principal {
    height: 5rem;
  }
}

#nav-principal.active {
  animation-name: navigation;
  animation-duration: .4s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  position: fixed;
}

#nav-principal .container {
  height: 100%;
}

@media screen and (max-width: 1200px) {
  #nav-principal .container {
    position: unset;
  }
}

#nav-principal .container .nav-item-logo {
  z-index: 999;
  position: relative;
}

@media screen and (max-width: 768px) {
  #nav-principal .container .nav-item-logo {
    max-width: 8.5431rem;
  }
}

#nav-principal .container .wrapper-nav-principal {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

#nav-principal .container .menu-principal {
  align-items: center;
  gap: 0 1.1694rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  #nav-principal .container .menu-principal {
    visibility: hidden;
    pointer-events: none;
    z-index: 998;
    background: #efebe0;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    height: calc(100vh - 6rem);
    transition: transform .4s ease-in, visibility .4s ease-in;
    position: fixed;
    top: 6rem;
    left: 0;
    transform: translateX(-100vw);
  }

  #nav-principal .container .menu-principal.active {
    visibility: visible;
    pointer-events: all;
    transform: translateX(0);
  }
}

@media screen and (max-width: 768px) {
  #nav-principal .container .menu-principal {
    height: calc(100vh - 5rem);
    top: 5rem;
  }
}

#nav-principal .container .menu-principal li a {
  letter-spacing: .2em;
  color: #1f2223;
  text-transform: uppercase;
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: color .3s ease-in;
}

#nav-principal .container .menu-principal li a:hover {
  color: var(--color-03);
}

#nav-principal .container .bt-mobile {
  cursor: pointer;
  z-index: 999;
  flex-direction: column;
  justify-content: center;
  gap: .625rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  position: relative;
}

@media screen and (max-width: 1200px) {
  #nav-principal .container .bt-mobile {
    display: flex;
  }
}

#nav-principal .container .bt-mobile span {
  pointer-events: none;
  background: #1f2223;
  width: 100%;
  height: 1px;
  transition: transform .2s ease-in;
  display: block;
}

#nav-principal .container .bt-mobile.active span {
  position: absolute;
}

#nav-principal .container .bt-mobile.active span:first-child {
  transform: rotate(45deg);
}

#nav-principal .container .bt-mobile.active span:nth-child(2) {
  transform: rotate(-45deg);
}

#footer {
  background: #4c749e;
  padding: 5rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  #footer {
    padding: 4rem 0;
  }
}

#footer .wrapper-footer {
  justify-content: space-between;
  align-items: center;
  gap: 8.5rem;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 1200px) {
  #footer .wrapper-footer {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  #footer .wrapper-footer {
    gap: 3rem;
  }

  #footer .logo img {
    max-width: 8.9244rem;
  }
}

#footer .nav-footer .wrapper-nav-footer {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  width: 100%;
  display: grid;
}

@media screen and (max-width: 1024px) {
  #footer .nav-footer .wrapper-nav-footer {
    grid-template-columns: repeat(2, 1fr);
    gap: 7.5rem 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .wrapper-nav-footer {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  #footer .nav-footer .wrapper-nav-footer > .content-1, #footer .nav-footer .wrapper-nav-footer > .content-2, #footer .nav-footer .wrapper-nav-footer > .content-3 {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

#footer .nav-footer .title-item-nav-footer {
  justify-content: flex-start;
  display: flex;
}

@media screen and (max-width: 1200px) {
  #footer .nav-footer .title-item-nav-footer {
    justify-content: center;
  }
}

#footer .nav-footer .title-item-nav-footer h4 {
  letter-spacing: .2em;
  color: #f1ece0;
  text-transform: uppercase;
  text-align: center;
  font-family: Raleway;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

#footer .nav-footer .main-item-nav-footer {
  margin-top: 3rem;
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer {
    margin-top: 1.5rem;
  }
}

#footer .nav-footer .main-item-nav-footer .list-item-nav-footer {
  flex-direction: column;
  gap: 1.875rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  #footer .nav-footer .main-item-nav-footer .list-item-nav-footer {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer .list-item-nav-footer {
    gap: 1rem;
  }
}

#footer .nav-footer .main-item-nav-footer .list-item-nav-footer li a {
  color: #f1ece0;
  font-family: Raleway;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: color .3s ease-in;
}

#footer .nav-footer .main-item-nav-footer .list-item-nav-footer li a:hover {
  color: #b89e7b;
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer .list-contact-network {
    gap: 1rem 0;
  }
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li .wrapper-link-contact {
  gap: 0 1.25rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer .list-contact-network li .wrapper-link-contact {
    gap: 0 .75rem;
  }
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li .wrapper-link-contact.center {
  align-items: center;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li svg {
  pointer-events: none;
  width: 2.5rem;
  height: 2.5rem;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li svg .svg-color-1 {
  fill: #f1ece0;
  stroke: #f1ece0;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li svg .circle-svg-1 {
  fill: #1e2122;
  stroke: #1e2122;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li svg .envelop-svg-1 {
  stroke: #1e2122;
  fill: #f1ece0;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li a {
  color: #f1ece0;
  max-width: 28ch;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: color .3s ease-in;
  display: inline-block;
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li a:hover {
  color: #b89e7b;
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer .list-contact-network li a {
    line-height: 160%;
  }
}

#footer .nav-footer .main-item-nav-footer .list-contact-network {
  flex-direction: column;
  gap: 1.875rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #footer .nav-footer .main-item-nav-footer .list-contact-network {
    gap: 1rem;
  }
}

#footer .nav-footer .main-item-nav-footer .list-contact-network li a {
  font-family: Raleway;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

#header {
  height: calc(100vh - 6rem);
  position: relative;
}

@media screen and (max-width: 768px) {
  #header {
    height: 23.5625rem;
  }

  #header.home_header {
    height: calc(100vh - 5rem);
  }
}

#header .content-header {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 5rem;
}

#header .background {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#header .background picture, #header .background figure {
  width: 100%;
  height: 100%;
}

#header .background picture img, #header .background figure img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

#login {
  background: linear-gradient(90deg, hsla(var(--hue-primary), 100%, 3%, 1) 0%, hsla(var(--hue-primary), 100%, 3%, 1) 100%);
  height: 100vh;
  padding-top: 3.75rem;
  position: relative;
}

@media (max-width: 1660px) {
  #login {
    height: 120vh;
  }
}

@media screen and (max-width: 1024px) {
  #login {
    height: 100vh;
  }
}

@media screen and (max-width: 600px) {
  #login {
    padding: unset;
  }
}

@media (max-width: 400px) {
  #login {
    height: 120vh;
  }
}

#login .ornamento {
  background: linear-gradient(92.74deg, hsla(var(--hue-primary), 68%, 32%, 1) 0%, hsla(var(--hue-primary), 100%, 73%, 1) 100%);
  border-radius: 11.875rem;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  max-height: 394px;
  margin: 0 auto;
}

@media (max-width: 1660px) {
  #login .ornamento {
    width: 90%;
    padding: 3rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento {
    height: 40%;
  }
}

@media screen and (max-width: 600px) {
  #login .ornamento {
    margin: unset;
    border-radius: 0 0 16px 16px;
    width: 100%;
    height: 349px;
  }
}

#login .ornamento .logo-cliente {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding-top: 4.6875rem;
}

@media (max-width: 1660px) {
  #login .ornamento .logo-cliente {
    padding-top: 2.75rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento .logo-cliente {
    padding-top: 2rem;
  }
}

#login .orn-sections {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#login .orn-sections img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#login .logo-ndt {
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1440px) {
  #login .logo-ndt {
    bottom: 64px;
  }
}

#login .form-wrapper {
  border: 1px solid var(--white-10-op);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background: #00000080;
  border-radius: 8px;
  width: 100%;
  max-width: 428px;
  height: -moz-fit-content;
  height: fit-content;
  margin: -210px auto 0;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  #login .form-wrapper {
    margin-top: -150px;
  }
}

@media screen and (max-width: 600px) {
  #login .form-wrapper {
    width: 327px;
  }
}

#login .form-wrapper .content .title {
  letter-spacing: -.02em;
  color: var(--white);
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#login .form-wrapper .content .description {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#login .form-wrapper .inputs-wrapper {
  margin-top: 2rem;
}

#login .form-wrapper .inputs-wrapper .input-item:first-child {
  margin-bottom: 1.5rem;
}

#login .form-wrapper .inputs-wrapper .input-item .input {
  border: unset;
  letter-spacing: -.02em;
  color: var(--white);
  background: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  height: 56px;
  padding: .5rem;
  font-family: Libre Caslon Text;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
  letter-spacing: -.02em;
  color: var(--white);
  font-family: Libre Caslon Text;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .show-password {
  display: flex;
  position: relative;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password svg {
  width: 24px;
  height: 24px;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password .hidden {
  display: none;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .hidden {
  display: block;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .show {
  display: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha {
  margin-top: .5rem;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .75rem;
  font-weight: 300;
  line-height: 167%;
  text-decoration: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .btn-wrapper {
  margin-top: 3.5rem;
}

#login .form-wrapper .btn-wrapper .btn {
  letter-spacing: -.02em;
  background-color: var(--primary-light-10-op);
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
  cursor: pointer;
  border-radius: 1.75rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
  display: flex;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .btn-wrapper .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#login .form-wrapper .btn-wrapper .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--neutral-10);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#login .form-wrapper .voltar {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto 0;
}

#login .form-wrapper .voltar a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .875rem;
  font-weight: 300;
  line-height: 157%;
}

#login .form-wrapper .voltar a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .checkbox-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container {
  align-items: center;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: -.02em;
  color: var(--white-70-op);
  align-items: center;
  gap: .375rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 150%;
  display: inline-flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label .checkbox {
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover {
  color: var(--white);
  font-weight: 400;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover .radio-circle {
  border-color: var(--primary-pure);
  border-width: 2px;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle {
  border: 1px solid #fff3;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: relative;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle-inner {
  background: var(--primary-pure);
  transition: all .2s ease-out;
  transition-property: width height;
  border-radius: 50%;
  flex-shrink: 0;
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span {
  border-color: var(--primary-pure);
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span i {
  width: 12px;
  height: 12px;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle {
  border-radius: .25rem;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle-inner {
  transition: initial;
  border-radius: initial !important;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round input:checked ~ span i {
  border-radius: initial;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDJMNy42MjY4MSAxMkwyIDUuOTk2OTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled {
  -webkit-user-select: none;
  user-select: none;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .check-label {
  pointer-events: none;
  color: var(--neutral-60);
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .radio-circle {
  pointer-events: none;
  opacity: .2;
}

.section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.section-header .title {
  letter-spacing: .2em;
  color: #e6dbcb;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.section-header .main {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .section-header .main {
    margin-top: 1.5rem;
  }
}

.section-header .main .text-area p {
  text-align: center;
  color: #1f2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.list-contact-network {
  flex-direction: column;
  gap: 3.5rem 0;
  display: flex;
}

@media screen and (max-width: 768px) {
  .list-contact-network {
    gap: 1rem 0;
  }
}

.list-contact-network li .wrapper-link-contact {
  gap: 0 1.25rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  .list-contact-network li .wrapper-link-contact {
    gap: 0 .75rem;
  }
}

.list-contact-network li .wrapper-link-contact.center {
  align-items: center;
}

.list-contact-network li svg {
  pointer-events: none;
  width: 2.5rem;
  height: 2.5rem;
}

.list-contact-network li svg .svg-color-1 {
  fill: #b79e7b;
  stroke: #b79e7b;
}

.list-contact-network li svg .circle-svg-1 {
  fill: #f1ece0;
  stroke: #f1ece0;
}

.list-contact-network li svg .envelop-svg-1 {
  stroke: #f1ece0;
  fill: #b79e7b;
}

.list-contact-network li a {
  color: #1f2223;
  max-width: 28ch;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: color .3s ease-in;
  display: inline-block;
}

.list-contact-network li a:hover {
  color: #654729;
}

@media screen and (max-width: 768px) {
  .list-contact-network li a {
    line-height: 160%;
  }
}

.header-bt-down {
  background: #fff3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  transition: transform .2s ease-in;
  display: flex;
}

.header-bt-down svg path {
  stroke: #5e492f;
}

.header-bt-down:hover {
  transform: scale(1.2);
}

html {
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 6em;
}

main {
  overflow: hidden;
}

.page__home #about_us {
  background: #b89e7b;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__home #about_us {
    padding: 4rem 0;
  }
}

.page__home #mission-vision-values {
  position: relative;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values:nth-child(2n) .content-1 {
  order: 2;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values:nth-child(2n) .content-2 {
  order: 1;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values {
  background: #f1ebdf;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  display: grid;
}

@media screen and (max-width: 768px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values {
    grid-template-columns: 1fr;
  }
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1, .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 {
  padding: 3.125rem 10.1563rem;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 .section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 .section-header .title {
  letter-spacing: .2em;
  color: #b09d7c;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 .section-header .main {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 .section-header .main {
    margin-top: 1.5rem;
  }
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 .section-header .main .text-area p {
  text-align: center;
  color: #1f2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 1200px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 {
    padding: 4rem 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 {
    padding: 4rem 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-1 {
    padding: 4rem 1.5rem;
    order: 2 !important;
  }
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 {
  height: 42.4375rem;
}

@media screen and (max-width: 768px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 {
    height: 26.25rem;
    order: 1 !important;
  }
}

@media screen and (max-width: 600px) {
  .page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 {
    height: 18.25rem;
  }
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 figure {
  width: 100%;
  height: 100%;
}

.page__home #mission-vision-values .box-mission-vision-values .card-mission-vision-values .wrapper-mission-vision-values .content-2 figure img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.page__home #product {
  background: #b89e7b;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__home #product {
    padding: 4rem 0;
  }
}

.page__home #stage {
  position: relative;
}

.page__home #stage .wrapper-stages {
  grid-template-columns: .7fr 1.7fr;
  width: 100%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .page__home #stage .wrapper-stages {
    grid-template-columns: 1fr;
  }
}

.page__home #stage .wrapper-stages > .content-1 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10rem 0;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .page__home #stage .wrapper-stages > .content-1 {
    display: none;
  }
}

.page__home #stage .wrapper-stages > .content-1 .background {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page__home #stage .wrapper-stages > .content-1 .background > img {
  object-fit: cover;
  object-position: right;
  width: 100%;
  height: 100%;
}

.page__home #stage .wrapper-stages > .content-2 {
  width: 100%;
  height: 100%;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__home #stage .wrapper-stages > .content-2 {
    padding: 4rem 0;
  }
}

.page__home #stage .wrapper-stages > .content-2 .background {
  z-index: -1;
  width: 120%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page__home #stage .wrapper-stages > .content-2 .background > img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
}

.page__home #stage .content-stage {
  max-width: 66rem;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .content-stage {
    max-width: unset;
    padding: 0 2.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .page__home #stage .content-stage {
    max-width: unset;
    padding: unset;
    width: 100%;
  }
}

.page__home #stage .content-stage > .header p {
  text-align: center;
  color: #f1ebe0;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.page__home #stage .box-stages {
  margin-top: 5.875rem;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .box-stages {
    display: none;
  }
}

.page__home #stage .box-stages .wrapper-stages {
  align-items: top;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 9.125rem 2.8125rem;
  display: flex;
}

.page__home #stage .box-stages .wrapper-stages .card-stage {
  width: max-content;
  min-width: 14.9375rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .box-stages .wrapper-stages .card-stage {
    width: 100%;
    min-width: auto;
  }
}

.page__home #stage .box-stages .wrapper-stages .card-stage .header-card-stage {
  border: 1px solid #f1ebe0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.75rem;
  padding: .75rem;
  display: flex;
}

.page__home #stage .box-stages .wrapper-stages .card-stage .header-card-stage .arrow-next-card-stage {
  animation-name: arrow_stages;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  position: absolute;
  top: -2.125rem;
  right: -4.625rem;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .box-stages .wrapper-stages .card-stage .header-card-stage .arrow-next-card-stage {
    top: .3rem;
    right: -1.5rem;
  }
}

.page__home #stage .box-stages .wrapper-stages .card-stage .header-card-stage h4 {
  letter-spacing: .2em;
  text-align: center;
  color: #f1ebe0;
  text-transform: uppercase;
  font-family: Raleway;
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.page__home #stage .box-stages .wrapper-stages .card-stage .main-card-stage {
  width: 100%;
  margin-top: 1.5rem;
}

.page__home #stage .box-stages .wrapper-stages .card-stage .main-card-stage .text-area-card-stage {
  justify-content: center;
  display: flex;
}

.page__home #stage .box-stages .wrapper-stages .card-stage .main-card-stage .text-area-card-stage p {
  text-align: center;
  color: #f1ebe0;
  max-width: 22ch;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.page__home #stage .box-stages .wrapper-stages .card-stage:nth-child(3) .arrow-next-card-stage, .page__home #stage .box-stages .wrapper-stages .card-stage:nth-child(5) .arrow-next-card-stage {
  display: none;
}

.page__home #stage .box-stages .wrapper-stages .card-stage:nth-child(4) .arrow-next-card-stage {
  right: -50%;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .box-stages .wrapper-stages {
    gap: 9.125rem 1.25rem;
  }
}

.page__home #stage .container-swiper {
  margin-top: 5.875rem;
  display: none;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .container-swiper {
    width: 100%;
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .page__home #stage .container-swiper {
    margin-top: 5.3125rem;
  }
}

.page__home #stage .container-swiper .swiper-stages {
  width: 100%;
}

.page__home #stage .container-swiper .swiper-stages .swiper-wrapper .swiper-slide:last-of-type .card-stage .arrow-next-card-stage {
  display: none;
}

.page__home #stage .container-swiper .swiper-stages .card-stage {
  width: max-content;
  min-width: 14.9375rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .container-swiper .swiper-stages .card-stage {
    width: 100%;
    min-width: auto;
  }
}

.page__home #stage .container-swiper .swiper-stages .card-stage .header-card-stage {
  border: 1px solid #f1ebe0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.75rem;
  padding: .75rem;
  display: flex;
}

.page__home #stage .container-swiper .swiper-stages .card-stage .header-card-stage .arrow-next-card-stage {
  animation-name: arrow_stages;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  position: absolute;
  top: -2.125rem;
  right: -4.625rem;
}

@media screen and (max-width: 1440px) {
  .page__home #stage .container-swiper .swiper-stages .card-stage .header-card-stage .arrow-next-card-stage {
    top: .3rem;
    right: -1.5rem;
  }
}

.page__home #stage .container-swiper .swiper-stages .card-stage .header-card-stage h4 {
  letter-spacing: .2em;
  text-align: center;
  color: #f1ebe0;
  text-transform: uppercase;
  font-family: Raleway;
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.page__home #stage .container-swiper .swiper-stages .card-stage .main-card-stage {
  width: 100%;
  margin-top: 1.5rem;
}

.page__home #stage .container-swiper .swiper-stages .card-stage .main-card-stage .text-area-card-stage {
  justify-content: center;
  display: flex;
}

.page__home #stage .container-swiper .swiper-stages .card-stage .main-card-stage .text-area-card-stage p {
  text-align: center;
  color: #f1ebe0;
  max-width: 22ch;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.page__home #stage .background {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page__home #stage .background picture {
  width: 100%;
  height: 100%;
}

.page__home #stage .background picture img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
}

.page__home #contact {
  background: #f1ece0;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__home #contact {
    padding: 4rem 0;
  }
}

.page__home #contact .section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.page__home #contact .section-header .title {
  letter-spacing: .2em;
  color: #1e2122;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.page__home #contact .section-header .main {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .page__home #contact .section-header .main {
    margin-top: 1.5rem;
  }
}

.page__home #contact .section-header .main .text-area p {
  text-align: center;
  color: #1e2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.page__home #contact .container {
  z-index: 3;
}

.page__home #contact .wrapper-contact {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .page__home #contact .wrapper-contact {
    align-items: center;
    justify-content: unset;
    flex-direction: column;
    gap: 2rem;
  }

  .page__home #contact .wrapper-contact > .content-1, .page__home #contact .wrapper-contact > .content-2 {
    width: 100%;
  }

  .page__home #contact .wrapper-contact > .content-1 {
    order: 2;
  }

  .page__home #contact .wrapper-contact > .content-2 {
    order: 1;
  }
}

.page__home #contact .form-enter-in-contact {
  width: 37rem;
}

@media screen and (max-width: 1200px) {
  .page__home #contact .form-enter-in-contact {
    width: 25rem;
  }
}

@media screen and (max-width: 1024px) {
  .page__home #contact .form-enter-in-contact {
    width: 100%;
  }
}

.page__home #contact .form-enter-in-contact .field-form {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
}

.page__home #contact .form-enter-in-contact .field-form:first-of-type {
  margin-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .page__home #contact .form-enter-in-contact .field-form {
    margin-top: 1rem;
  }
}

.page__home #contact .form-enter-in-contact .field-form label {
  color: #1f2223;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.page__home #contact .form-enter-in-contact .field-form input, .page__home #contact .form-enter-in-contact .field-form textarea {
  color: #1f2223;
  background: none;
  border: 1px solid #b09d7c;
  width: 100%;
  padding: .75rem;
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: border .2s ease-in;
}

.page__home #contact .form-enter-in-contact .field-form input:focus, .page__home #contact .form-enter-in-contact .field-form textarea:focus {
  border: 1px solid #654729;
}

.page__home #contact .form-enter-in-contact .field-form input {
  height: 4.5rem;
}

@media screen and (max-width: 768px) {
  .page__home #contact .form-enter-in-contact .field-form input {
    height: 4rem;
  }
}

.page__home #contact .form-enter-in-contact .field-form textarea {
  resize: none;
  height: 10.4375rem;
}

.page__home #contact .form-enter-in-contact .bt-enter-in-contact {
  cursor: pointer;
  color: #f1ece0;
  background: #654729;
  margin-top: 2.6875rem;
  padding: 1.9063rem 1.375rem;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: background .3s ease-in;
}

@media screen and (max-width: 1024px) {
  .page__home #contact .form-enter-in-contact .bt-enter-in-contact {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .page__home #contact .form-enter-in-contact .bt-enter-in-contact {
    margin-top: 1.5rem;
    padding: 1.5rem 1.25rem;
  }
}

.page__home #contact .form-enter-in-contact .bt-enter-in-contact:hover, .page__home #contact .form-enter-in-contact .bt-enter-in-contact:focus {
  background: #1f2223;
}

.page__home #contact .background {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .page__home #contact .background {
    display: none;
  }
}

.page__compliance #compliance {
  padding: 9.5rem 0 17.75rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance {
    padding: 4rem 0;
  }
}

.page__compliance #compliance .box-content-compliance {
  max-width: 64.75rem;
  margin: 6.75rem auto 0;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-content-compliance {
    margin: 2rem auto 0;
  }
}

.page__compliance #compliance .box-content-compliance .wrapper-compliance {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  width: 100%;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .page__compliance #compliance .box-content-compliance .wrapper-compliance {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-content-compliance .wrapper-compliance {
    grid-template-columns: 1fr;
  }
}

.page__compliance #compliance .box-content-compliance .wrapper-compliance .row-links {
  margin-top: 0;
}

.page__compliance #compliance .box-content-compliance .row-links {
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

.page__compliance #compliance .box-content-compliance .row-links:first-of-type {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-content-compliance .row-links {
    gap: 1rem;
    margin-top: 1.5rem;
  }
}

.page__compliance #compliance .box-content-compliance .row-links p {
  text-align: center;
  color: #efeadf;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.3rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.page__compliance #compliance .box-content-compliance .row-links .group-buttons {
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-content-compliance .row-links .group-buttons {
    flex-direction: column;
  }
}

.page__compliance #compliance .box-content-compliance .row-links .group-buttons a {
  text-align: center;
  color: #f1ece0;
  background: #b09d7d;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 5.313rem;
  padding: 1rem;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-content-compliance .row-links .group-buttons a {
    width: 100%;
    line-height: 160%;
  }
}

.page__compliance #compliance .box-text-italic {
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  .page__compliance #compliance .box-text-italic {
    margin-top: 1.5rem;
  }
}

.page__compliance #compliance .box-text-italic i {
  text-align: center;
  color: #efeadf;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.75rem);
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
}

.page__compliance #compliance .background {
  z-index: -1;
  background: #5e492f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page__compliance #compliance .background figure {
  width: 100%;
  height: 100%;
}

.page__compliance #compliance .background figure img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
}

.page__compliance #contact {
  background: #f1ece0;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__compliance #contact {
    padding: 4rem 0;
  }
}

.page__compliance #contact .section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.page__compliance #contact .section-header .title {
  letter-spacing: .2em;
  color: #1e2122;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.page__compliance #contact .section-header .main {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .page__compliance #contact .section-header .main {
    margin-top: 1.5rem;
  }
}

.page__compliance #contact .section-header .main .text-area p {
  text-align: center;
  color: #1e2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.page__compliance #contact .container {
  z-index: 3;
}

.page__compliance #contact .wrapper-contact {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .page__compliance #contact .wrapper-contact {
    align-items: center;
    justify-content: unset;
    flex-direction: column;
    gap: 2rem;
  }

  .page__compliance #contact .wrapper-contact > .content-1, .page__compliance #contact .wrapper-contact > .content-2 {
    width: 100%;
  }

  .page__compliance #contact .wrapper-contact > .content-1 {
    order: 2;
  }

  .page__compliance #contact .wrapper-contact > .content-2 {
    order: 1;
  }
}

.page__compliance #contact .form-enter-in-contact {
  width: 37rem;
}

@media screen and (max-width: 1200px) {
  .page__compliance #contact .form-enter-in-contact {
    width: 25rem;
  }
}

@media screen and (max-width: 1024px) {
  .page__compliance #contact .form-enter-in-contact {
    width: 100%;
  }
}

.page__compliance #contact .form-enter-in-contact .field-form {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
}

.page__compliance #contact .form-enter-in-contact .field-form:first-of-type {
  margin-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .page__compliance #contact .form-enter-in-contact .field-form {
    margin-top: 1rem;
  }
}

.page__compliance #contact .form-enter-in-contact .field-form label {
  color: #1f2223;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.page__compliance #contact .form-enter-in-contact .field-form input, .page__compliance #contact .form-enter-in-contact .field-form textarea {
  color: #1f2223;
  background: none;
  border: 1px solid #b09d7c;
  width: 100%;
  padding: .75rem;
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: border .2s ease-in;
}

.page__compliance #contact .form-enter-in-contact .field-form input:focus, .page__compliance #contact .form-enter-in-contact .field-form textarea:focus {
  border: 1px solid #654729;
}

.page__compliance #contact .form-enter-in-contact .field-form input {
  height: 4.5rem;
}

@media screen and (max-width: 768px) {
  .page__compliance #contact .form-enter-in-contact .field-form input {
    height: 4rem;
  }
}

.page__compliance #contact .form-enter-in-contact .field-form textarea {
  resize: none;
  height: 10.4375rem;
}

.page__compliance #contact .form-enter-in-contact .bt-enter-in-contact {
  cursor: pointer;
  color: #f1ece0;
  background: #654729;
  margin-top: 2.6875rem;
  padding: 1.9063rem 1.375rem;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: background .3s ease-in;
}

@media screen and (max-width: 1024px) {
  .page__compliance #contact .form-enter-in-contact .bt-enter-in-contact {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .page__compliance #contact .form-enter-in-contact .bt-enter-in-contact {
    margin-top: 1.5rem;
    padding: 1.5rem 1.25rem;
  }
}

.page__compliance #contact .form-enter-in-contact .bt-enter-in-contact:hover, .page__compliance #contact .form-enter-in-contact .bt-enter-in-contact:focus {
  background: #1f2223;
}

.page__compliance #contact .background {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .page__compliance #contact .background {
    display: none;
  }
}

.page__compliance .header-bt-down {
  background: #5e492fcc;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  transition: transform .2s ease-in;
  display: flex;
}

.page__compliance .header-bt-down svg path {
  stroke: #fff;
}

.page__compliance .header-bt-down:hover {
  transform: scale(1.2);
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us {
  background: #b89e7b;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us {
    grid-template-columns: 1fr;
  }
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 {
  background: #b89e7b;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40.4375rem;
  padding: 4.5rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 {
    order: 2;
    padding: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 {
    min-height: auto;
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 {
    padding: 4rem 1.5rem;
  }
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .title {
  letter-spacing: .2em;
  color: #f1ebdf;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .main {
    margin-top: 1.5rem;
  }
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .main .text-area p {
  text-align: center;
  color: #1f2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header {
  font-size: 2.25rem;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .title {
  text-align: center;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .main {
  margin-top: 2.25rem;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-1 .section-header .main .text-area p {
  max-width: 42ch;
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .content-2 {
    order: 1;
    height: 18.25rem;
  }
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .group-buttons {
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .group-buttons a {
  color: #f1ece0;
  text-align: center;
  background: #654729;
  padding: 1.9063rem 1.375rem;
  font-family: Raleway;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: background .3s ease-in;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #work-with-us .wrapper-work-with-us .group-buttons a {
    width: 100%;
  }
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .group-buttons a:hover {
  background: #1e2122;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .img-man-work {
  width: 100%;
  height: 100%;
}

.page__trabalhe_conosco #work-with-us .wrapper-work-with-us .img-man-work img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.page__trabalhe_conosco #contact {
  background: #f1ece0;
  padding: 10rem 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact {
    padding: 4rem 0;
  }
}

.page__trabalhe_conosco #contact .section-header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.page__trabalhe_conosco #contact .section-header .title {
  letter-spacing: .2em;
  color: #1e2122;
  text-transform: uppercase;
  text-align: center;
  font-family: Cormorant;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.page__trabalhe_conosco #contact .section-header .main {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact .section-header .main {
    margin-top: 1.5rem;
  }
}

.page__trabalhe_conosco #contact .section-header .main .text-area p {
  text-align: center;
  color: #1e2122;
  font-family: Libre Caslon Text;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.page__trabalhe_conosco #contact .container {
  z-index: 3;
}

.page__trabalhe_conosco #contact .wrapper-contact {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #contact .wrapper-contact {
    align-items: center;
    justify-content: unset;
    flex-direction: column;
    gap: 2rem;
  }

  .page__trabalhe_conosco #contact .wrapper-contact > .content-1, .page__trabalhe_conosco #contact .wrapper-contact > .content-2 {
    width: 100%;
  }

  .page__trabalhe_conosco #contact .wrapper-contact > .content-1 {
    order: 2;
  }

  .page__trabalhe_conosco #contact .wrapper-contact > .content-2 {
    order: 1;
  }
}

.page__trabalhe_conosco #contact .form-enter-in-contact {
  width: 37rem;
}

@media screen and (max-width: 1200px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact {
    width: 25rem;
  }
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact {
    width: 100%;
  }
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form:first-of-type {
  margin-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact .field-form {
    margin-top: 1rem;
  }
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form label {
  color: #1f2223;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form input, .page__trabalhe_conosco #contact .form-enter-in-contact .field-form textarea {
  color: #1f2223;
  background: none;
  border: 1px solid #b09d7c;
  width: 100%;
  padding: .75rem;
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: border .2s ease-in;
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form input:focus, .page__trabalhe_conosco #contact .form-enter-in-contact .field-form textarea:focus {
  border: 1px solid #654729;
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form input {
  height: 4.5rem;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact .field-form input {
    height: 4rem;
  }
}

.page__trabalhe_conosco #contact .form-enter-in-contact .field-form textarea {
  resize: none;
  height: 10.4375rem;
}

.page__trabalhe_conosco #contact .form-enter-in-contact .bt-enter-in-contact {
  cursor: pointer;
  color: #f1ece0;
  background: #654729;
  margin-top: 2.6875rem;
  padding: 1.9063rem 1.375rem;
  font-family: Raleway;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: background .3s ease-in;
}

@media screen and (max-width: 1024px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact .bt-enter-in-contact {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact .form-enter-in-contact .bt-enter-in-contact {
    margin-top: 1.5rem;
    padding: 1.5rem 1.25rem;
  }
}

.page__trabalhe_conosco #contact .form-enter-in-contact .bt-enter-in-contact:hover, .page__trabalhe_conosco #contact .form-enter-in-contact .bt-enter-in-contact:focus {
  background: #1f2223;
}

.page__trabalhe_conosco #contact .background {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .page__trabalhe_conosco #contact .background {
    display: none;
  }
}

/*# sourceMappingURL=style.css.map */
