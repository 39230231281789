

#nav-principal{
    $height_nav: 6rem;
    $mobile_height_nav: 5rem;

    height: $height_nav;
    background: #EFEBE0;
    position: relative;
    z-index: 999;
    width: 100%;

    &::after{
        content: "";
        background: #5e492f;
        width: 100%;
        height: .125rem;
        display: block;
        
        transform: translateX(0);
        transition: transform 1s linear;
        transition-delay: 1.6s;
    }

    @include d(md2){
        height: $mobile_height_nav;
    }
    

    &.active{
        position: fixed;
        animation-name: navigation;
        animation-timing-function: linear;
        animation-duration: .4s;
        animation-iteration-count: 1;

    }
    .container{
        height: 100%;
        @include d(lg2){
            position: unset;
        }

        .nav-item-logo{
            position: relative;
            z-index: 999;

            @include d(md2){
                max-width: 8.5431rem;
            }
        }
        .wrapper-nav-principal{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

        }

        .menu-principal{
            display: flex;
            align-items: center;
            gap: 0 1.1694rem;

            @include d(lg2){
                visibility: hidden;
                pointer-events: none;
                transform: translateX(-100vw);

                
                position: fixed;
                top: $height_nav;
                left: 0;
                background: #EFEBE0;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: calc(100vh - $height_nav);
                z-index: 998;
                gap: 3rem;

                transition: .4s ease-in;
                transition-property: transform, visibility;

                &.active{
                    transform: translateX(0vh);
                    visibility: visible;
                    pointer-events: all;
                }
                
            }

            @include d(md2){
                top: $mobile_height_nav;
                height: calc(100vh - $mobile_height_nav);
            }
            li{
                a{
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 100%;

                    /* identical to box height, or 16px */
                    letter-spacing: 0.2em;

                    color: #1F2223;

                    text-transform: uppercase;
                    transition: color .3s ease-in;

                    &:hover{
                        color: $color-03;
                    }
                }
            }
        }

        .bt-mobile{
            height: 1.5rem;
            width: 1.5rem;
            display: none;
            flex-direction: column;
            justify-content: center;
            gap: .625rem;
            cursor: pointer;

            position: relative;
            z-index: 999;

            @include d(lg2){
                display: flex;
            }
            span{
                background: #1F2223;
                display: block;
                height: 1px;
                width: 100%;
                pointer-events: none;

                transition: transform .2s ease-in;
            }

            &.active{
                span{
                    position: absolute;
                    &:nth-child(1){
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}