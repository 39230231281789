#work-with-us{
    .wrapper-work-with-us{
        background: #B89E7B;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        @include d(md){
            grid-template-columns: 1fr;
        }
        .content-1{
            padding: 4.5rem;
            background: #B89E7B;
            min-height: 40.4375rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include d(md){
                order: 2;
                padding: 3rem;
            }

            @include d(md2){
                padding: 4rem 2rem;
                min-height: auto;
            }

            @include d(sm){
                padding: 4rem 1.5rem;
            }

            @include d(lg){
                //padding: 8.5625rem 3.75rem;
            }

            @include header_section($color-title: #F1EBDF, $color-text: #1F2122);

            .section-header{
                font-size: 2.25rem;
                .title{
                    text-align: center;
                    //line-height: 120%;
                }
                .main{
                    margin-top: 2.25rem;
                    .text-area{
                        p{
                            max-width: 42ch;
                        }
                    }
                }
            }
        }

        .content-2{
            @include d(md){
                height: 18.25rem;
                order: 1;
            }
        }

        .group-buttons{
            margin-top: 2.25rem;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1.25rem;
            a{
                padding: 1.9063rem 1.375rem;
                background: #654729;

                font-family: $font-3;
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 100%;

                /* identical to box height, or 24px */

                color: #F1ECE0;
                text-align: center;

                transition: background .3s ease-in;
               /* text-transform: uppercase;*/

                @include d(md2){
                    width: 100%;
                }

                &:hover{
                    background: #1e2122;
                }
            }
        }

        .img-man-work{
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}