#stage{
    position: relative;

    .wrapper-stages{
        display: grid;
        grid-template-columns: .7fr 1.7fr;
        width: 100%;

        @include d(lg2){
            grid-template-columns: 1fr;
        }

        & > .content-1{
            
            padding: 10rem 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include d(lg2){
                display: none;
            }

            .background{
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                height: 100%;
                width: 100%;
                & > img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: right;
                }
            }
        }

        & > .content-2{
            padding: 10rem 0;
            height: 100%;
            width: 100%;
            position: relative;

            @include d(md2){
                padding: 4rem 0;
            }

            .background{
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                height: 100%;
                width: 120%;
                & > img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: left;
                }
            }
        }
    }

    .content-stage{
        //padding: 0 2.5rem;
        max-width: 66rem;

        @include d(lg){
            max-width: unset;
            padding: 0 2.5rem; 
        }

        @include d(lg2){
            max-width: unset;
            width: 100%;
            padding: unset;
        }
        & > .header{
            p{
                font-family: $font-1;
                font-style: normal;
                font-weight: 400;
                font-size: clamp(1rem, 3vw, 1.875rem);
                line-height: 160%;

                /* or 48px */
                text-align: center;

                color: #F1EBE0;
            }
        }
        
    }

    .box-stages{
        margin-top: 5.875rem;

        @include d(lg){
            display: none;
        }
        .wrapper-stages{
            display: flex;
            align-items: top;
            gap: 9.125rem 2.8125rem;
            flex-wrap: wrap;
            justify-content: space-evenly;

            
            @import "../../components/cards/card_stage";
            .card-stage{
                &:nth-child(3), &:nth-child(5){
                    .arrow-next-card-stage{
                        display: none;
                    }
                }
                &:nth-child(4){
                    .arrow-next-card-stage{
                        right: -50%;
                    }
                }
            }

            @include d(lg){
                gap: 9.125rem 1.25rem;
                
            }

        }
    }

    .container-swiper{
        margin-top: 5.875rem;
        display: none;

        @include d(lg){
            display: grid;
            width: 100%;
        }

        @include d(md2){
            margin-top: 5.3125rem;
        }

        .swiper-stages{
            width: 100%;
            .swiper-wrapper{
                .swiper-slide{
                    &:last-of-type{
                        .card-stage{
                            .arrow-next-card-stage{
                                display: none;
                            }
                        }
                    
                    }

                }
            }
            @import "../../components/cards/card_stage";

        }
    
    }
    .background{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        picture{
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: left;
            }
        }
    }
}