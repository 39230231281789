@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";

//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/header";
@import "../sass/components/login";

@include header_section();
@include list_contact_network();
@include header_bt_down();

// STYLE SALEBRAND
/*@import "../sass/sections/salebrand/salebrand_style";
*/
// PÁGINAS
html{
  scroll-behavior: smooth;
}
:target {
  scroll-margin-top: 6em;
}
main {
  overflow: hidden;
}

.page__home{
  @import "../sass/pages/page_home";
}
.page__compliance{
  @import "../sass/pages/page_compliance";
}
.page__trabalhe_conosco{
  @import "../sass/pages/page_trabalhe_conosco";
}
