#contact{
    background: #F1ECE0;
    position: relative;
    padding: 10rem 0;

    @include d(md2){
        padding: 4rem 0;
    }

    @include header_section(#1E2122, #1E2122);
    .container{
        z-index: 3;
    }

    .wrapper-contact{
        margin-top: 4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        @include d(md){
            flex-direction: column;
            align-items: center;
            justify-content: unset;
            gap: 2rem;
        }

        & > .content-1, & > .content-2{
            @include d(md){
                width: 100%;
            }
        }

        & > .content-1{
            @include d(md){
                order: 2;
            }
        }
        & > .content-2{
            @include d(md){
                order: 1;
            }
        }
    }

    .form-enter-in-contact{
        width: 37rem;

        @include d(lg2){
            width: 25rem;
        }
        @include d(md){
            width: 100%;
        }
        .field-form{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            margin-top: 2.5rem;
            &:first-of-type{
                margin-top: 0rem !important;
            }

            @include d(md2){
                margin-top: 1rem;
            }
            label{
                font-family: $font-3;
                font-style: normal;
                font-weight: 600;
                font-size: clamp(1rem, 3vw, 1.5rem);
                line-height: 100%;

                /* identical to box height, or 24px */

                color: #1F2223;
            }

            input, textarea{
                border: 1px solid #B09D7C;
                background: transparent;
                padding: .75rem;

                font-family: $font-3;
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                line-height: 100%;

                /* identical to box height, or 24px */

                color: #1F2223;

                transition: border .2s ease-in;

                width: 100%;

                &:focus{
                    border: 1px solid #654729;
                }
            }
            input{
                height: 4.5rem;

                @include d(md2){
                    height: 4rem;
                }
            }
            textarea{
                height: 10.4375rem;
                resize: none;
            }
        }

        .bt-enter-in-contact{
            margin-top: 2.6875rem;

            background: #654729;

            cursor: pointer;
            font-family: $font-3;
            font-style: normal;
            font-weight: 600;
            font-size: clamp(1rem, 3vw, 1.5rem);
            line-height: 100%;

            /* identical to box height, or 24px */

            padding: 1.9063rem 1.375rem;

            color: #F1ECE0;
            transition: background .3s ease-in;

            @include d(md){
                width: 100%;
            }

            @include d(md2){
                margin-top: 1.5rem;
                padding: 1.5rem 1.25rem;
            }

            &:hover, &:focus{
                background: #1F2223;
            }
        }
    }

    .background{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;

        @include d(md2){
            display: none;
        }
    }
}