//colocar  as variaveis de cor  do projeto no body como variaveis css
body {
    // Generics
    /*--white: #FFFFFF;
    --white-70-op: hsla(0, 0%, 100%, 0.7);
    --white-10-op: hsla(0, 0%, 100%, 0.1);
    --black: #000000;*/

    // Neutral
    --neutral-100: #090A1A;
    --neutral-70: #464651;
    --neutral-60: #666670;
    --neutral-op-50: #090A1A80;
    --neutral-op-40: #090A1A66;
    --neutral-op-10: #090A1A1A;
    --neutral-op-05: #090A1A0D;
    --neutral-30: #E5E6EB;
    --neutral-20: #F0F1F5;
    --neutral-10: #F5F6FA;

    // Colors
    --color-01: #1E2124;
    --color-02: #626263;
    --color-03: #5E492F;
    --color-04: #B09D7C;
    --color-05: #EFEADF;

    
    // Primary
    --primary-pure: #B09D7C;

    --hue-primary: 230;
    --white-10-op: hsla(0, 0%, 100%, 0.1);
    --white: #ffffff;
    --neutral-10: #f5f6fa;
    --primary-light: #8186f7;
    --primary-light-10-op: hsla(237, 88%, 74%, 0.1);
    --white-70-op: hsla(0, 0%, 100%, 0.7);
   // --primary-pure: hsla(var(--hue-primary), 100%, 59%, 1);

    // Black and White
    --white: #F8F9FA;
    --black: #F1F3F5;

    --alert-error:#E92C2C;
    --alert-error-10:#FDEAEA;
    --alert-warning:#D2AF00;
    --alert-warning-10:#FBF7E6;
    --alert-sucess:#008024;
    --alert-sucess-10:#E6F9F0;

}

/** NAME FONTE */
$font-1: 'Libre Caslon Text';
$font-2: 'Cormorant';
$font-3: 'Raleway';


/**  NEUTRAL  */
$neutral-100: #{var(--neutral-100)};
$neutral-70: #{var(--neutral-70)};
$neutral-60: #{var(--neutral-60)};
$neutral-op-50: #{var(--neutral-op-50)};
$neutral-op-40: #{var(--neutral-op-40)};
$neutral-op-10: #{var(--neutral-op-10)};
$neutral-op-05: #{var(--neutral-op-05)};
$neutral-30: #{var(--neutral-30)};
$neutral-20: #{var(--neutral-20)};
$neutral-10: #{var(--neutral-10)};


/**  PRIMARY  */
$primary-pure: #{var(--primary-pure)};

/**  COLORS  */
$color-01: #{var(--color-01)};
$color-02: #{var(--color-02)};
$color-03: #{var(--color-03)};
$color-04: #{var(--color-04)};
$color-05: #{var(--color-05)};


/**  BLACK AND WHITE  */
$black: #{var(--black)};
$white: #{var(--white)};


/*$shadow-sm-1: 0px 0px 2px rgba(0, 0, 0, 0.06);
$shadow-sm-2: 0px 2px 4px rgba(149, 157, 165, 0.08);
$shadow-sm-2: 0px 0px 2px rgba(104, 110, 117, 0.2);

$shadow-md-1: 0px 4px 8px rgba(0, 0, 0, 0.06);
$shadow-md-2: 2px 4px 16px rgba(0, 0, 0, 0.06);
$shadow-md-3: 2px 8px 32px rgba(0, 0, 0, 0.04);

$shadow-lg-1: 2px 6px 24px -2px rgba(149, 157, 165, 0.06);
$shadow-lg-2: 0px 24px 64px rgba(139, 152, 149, 0.06), 2px 8px 32px rgba(175, 185, 183, 0.06);
$shadow-lg-3: 2px 24px 64px -20px rgba(0, 0, 0, 0.06);*/


// forma de utilizar variaveis css com a syntaxe do sass

// $cor-preto-1:#{var(--cor-preto-1)};

