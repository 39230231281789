


#header{
    $header_height_nav: 6rem;
    $mobile_header_height_nav: 5rem;
    


    height: calc(100vh - $header_height_nav);
    position: relative;

    @include d(md2){
        height: calc(28.5625rem - $mobile_header_height_nav);

        &.home_header{
            height: calc(100vh - $mobile_header_height_nav);
        }
    }

    .content-header{
        position: absolute;
        z-index: 1;
        bottom: 5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .background{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        picture, figure{
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}