#compliance{
    position: relative;
    padding: 9.5rem 0 17.75rem 0;

    @include d(md2){
        padding: 4rem 0;
    }


    .box-content-compliance{
       // margin-top: 6.75rem;
        margin: 6.75rem auto 0 auto;
        max-width: 64.75rem;
        @include d(md2){
            margin: 2rem auto 0 auto;
        }

        .wrapper-compliance{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            width: 100%;

            @include d(md){
                grid-template-columns: 1fr 1fr;
            }

            @include d(md2){
                grid-template-columns: 1fr;
            }

            .row-links{
                margin-top: 0;
            }
        }
        .row-links{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            gap: 3.5rem;

            margin-top: 4rem;
            &:first-of-type{
                margin-top: 0;
            }

            @include d(md2){
                margin-top: 1.5rem;
                gap: 1rem;
            }

            p{
                font-family: $font-1;
                font-style: normal;
                font-weight: 400;
                font-size: clamp(1rem, 3vw, 1.3rem);
                line-height: 150%;

                /* identical to box height, or 45px */
                text-align: center;

                color: #EFEADF;
            }

            .group-buttons{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 1.5rem;

                @include d(md2){
                    flex-direction: column;
                }
                a{
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    min-height: 5.313rem;


                    font-family: $font-3;
                    font-style: normal;
                    font-weight: 600;
                    font-size: clamp(1rem, 3vw, 1rem);
                    line-height: 100%;
                    width: 100%;

                    text-align: center;

                    /* identical to box height, or 24px */

                    color: #F1ECE0;
                    background: #B09D7D;

                    padding: 1rem;

                    @include d(md2){
                        width: 100%;
                        line-height: 160%;
                    }
                }
            }
        }
    }

    .box-text-italic{
        margin-top: 4rem;
        display: flex;
        justify-content: center;
        width: 100%;

        @include d(md2){
            margin-top: 1.5rem;
        }
        i{
            font-family: $font-1;
            font-style: italic;
            font-weight: 400;
            font-size: clamp(1rem, 3vw, 1.75rem);
            line-height: 150%;
    
            /* or 42px */
            text-align: center;
    
            color: #EFEADF;
        }
    }
    


    .background{
        background: #5E492F;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        figure{
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: left; 
            }
        }
    }
}